import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import {
  OrganizationManagementService,
  OrganizationResponse,
  UserSyncResponse,
} from '@/open-api';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import APIConfig from '../../../configs/urlConfig';
import { acquireAccessToken } from '@/shared/helpers/getAccessToken';

const updateOrganization = async (orgId: string): Promise<void> => {
  const tempToken = await acquireAccessToken();
  const config = {
    crossDomain: true,
    headers: {
      Authorization: `Bearer ${tempToken}`,
      'Ocp-Apim-Subscription-Key': APIConfig.subscriptionKey,
    },
  };
  await axios
    .patch(
      `${APIConfig.apiUrl}/users/me/selectedOrganization`,
      {
        selectedOrganization: orgId,
      },
      config
    )
    .then(res => {
      return res.data;
    });
};

export const useGetCurrentOrganization = (): {
  handleLogout: (logoutType: string) => void;
  handleOrgUpdate: (orgId: string, userData?: UserSyncResponse) => void;
} => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  const handleLogout = (logoutType: string): void => {
    if (logoutType === 'popup') {
      instance.logoutPopup({
        postLogoutRedirectUri: '/',
        mainWindowRedirectUri: '/',
      });
    } else if (logoutType === 'redirect') {
      sessionStorage.clear();
      instance.logoutRedirect({
        postLogoutRedirectUri: '/',
      });
    }
  };

  const handleOrgUpdate = (
    orgId: string,
    userData?: UserSyncResponse
  ): void => {
    navigate('/org-switcher', {
      state: { userData: userData },
    });
    updateOrganization(orgId);
  };

  return {
    handleLogout,
    handleOrgUpdate,
  };
};

function useGetAllOrganizationsQuery(): UseQueryResult<
  OrganizationResponse[],
  unknown
> {
  return useQuery<OrganizationResponse[]>({
    queryKey: ['getAllOrganization'],
    queryFn: () => OrganizationManagementService.getAllOrganizations(),
  });
}

export default useGetAllOrganizationsQuery;
