import { Button, Icon, Modal } from '@cae/cobalt-react';
import './UserDiscrepancyModal.css';
import { UserResponse } from '@/open-api';
type UserDiscrepancyModalProps = {
  readonly open: boolean;
  readonly syncUserDetails?: UserResponse;
  readonly setOpenDisModal: React.Dispatch<React.SetStateAction<boolean>>;
  readonly handleSyncClick: () => void;
};
interface UpdateDiscrepancyActionsProps {
  handleCancel: React.MouseEventHandler<HTMLButtonElement>;
  handleConfirm: React.MouseEventHandler<HTMLButtonElement>;
}

function CheckActions({
  handleCancel,
  handleConfirm,
}: Readonly<UpdateDiscrepancyActionsProps>): JSX.Element {
  return (
    <>
      <Button
        id="submit-user-discrepancy"
        type="button"
        variant="primary"
        onClick={handleConfirm}
      >
        Update CAE Connect
      </Button>
      <Button
        id="cancel-user-discrepancy"
        type="button"
        variant="secondary"
        onClick={handleCancel}
      >
        Cancel
      </Button>
    </>
  );
}

const UserDiscrepancyModal = ({
  open,
  syncUserDetails,
  setOpenDisModal,
  handleSyncClick,
}: UserDiscrepancyModalProps): JSX.Element => {
  const sfName =
    `${syncUserDetails?.additionalAttrs?.sfFirstName ?? ''} ${syncUserDetails?.additionalAttrs?.sfLastName ?? ''}`.trim();
  const sfEmail = syncUserDetails?.additionalAttrs?.sfEmailId ?? '';
  const name = `${syncUserDetails?.firstName} ${syncUserDetails?.lastName ?? ''}`;
  const email = syncUserDetails?.email ?? '';

  const handleCopy = (
    event: React.MouseEvent<HTMLButtonElement>,
    text: string
  ): void => {
    event.stopPropagation();
    navigator.clipboard.writeText(text);
  };
  return (
    <Modal
      actions={CheckActions}
      className="user-discrepancy"
      dismissible={false}
      id="submit-discrepnacy"
      open={open}
      header={
        <h2>
          Discrepancies for{' '}
          {`${syncUserDetails?.firstName} ${syncUserDetails?.lastName}`}
        </h2>
      }
      onCancel={() => {
        setOpenDisModal(false);
      }}
      onConfirm={() => {
        handleSyncClick();
      }}
      placement="center"
      size="sm"
    >
      <div className="discrepancy-modal-content">
        <div className="user-discrepancy-details-main">
          <div className="user-discrepancy-sf-connect-details">
            <div className="user-discrepancy--icon">
              <Icon id="user-management-outline" />
            </div>
            <div>
              <h4 className="user-discrepancy-h4">Data in Salesforce</h4>
              {sfName && (
                <p className="user-details">
                  {sfName}
                  <button
                    onClick={event => handleCopy(event, sfName)}
                    aria-label="Copy sf name"
                    type="button"
                    className="copy-button"
                  >
                    <Icon id="duplicate-outline" size="sm" />
                  </button>
                </p>
              )}
              {sfEmail && (
                <p className="user-details">
                  {String(sfEmail)}
                  <button
                    onClick={event => handleCopy(event, String(sfEmail))}
                    aria-label="Copy sf email"
                    type="button"
                    className="copy-button"
                  >
                    <Icon id="duplicate-outline" size="sm" />
                  </button>
                </p>
              )}
            </div>
          </div>

          <Icon
            id="move-forward-outline"
            className="discrepancy-resolve-icon"
          />

          <div className="user-discrepancy-portal-details">
            <div className="user-discrepancy--icon">
              <Icon id="user-management-outline" />
            </div>
            <div>
              <h4 className="user-discrepancy-h4">Data in CAE Account</h4>
              <p className="user-details">
                {name}
                <button
                  onClick={event => handleCopy(event, String(sfEmail))}
                  aria-label="Copy name"
                  type="button"
                  className="copy-button"
                >
                  <Icon id="duplicate-outline" size="sm" />
                </button>
              </p>
              <p className="user-details">
                {email}
                <button
                  onClick={event => handleCopy(event, String(sfEmail))}
                  aria-label="Copy email"
                  type="button"
                  className="copy-button"
                >
                  <Icon id="duplicate-outline" size="sm" />
                </button>
              </p>
            </div>
          </div>
        </div>
        <p>
          To resolve this, you can click the &quot;Update CAE Connect&quot;
          button below to sync with Salesforce data.
        </p>
      </div>
    </Modal>
  );
};

export default UserDiscrepancyModal;
