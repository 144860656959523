import { useFlags } from 'launchdarkly-react-client-sdk';
import { CustomerDocumentsPage } from './CustomerDocuments';
import { CustomerDocumentsOldPage } from './CustomerDocumentsOldPage';

function CompanyDocumentsPageWrapper(): JSX.Element {
  const { companydocumentsnewimplementation } = useFlags();
  return !companydocumentsnewimplementation ? (
    <CustomerDocumentsPage />
  ) : (
    <CustomerDocumentsOldPage />
  );
}

export default CompanyDocumentsPageWrapper;
