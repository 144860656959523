import { Modal, AlertMessage, Button } from '@cae/cobalt-react';
import { FieldValues, SubmitHandler } from 'react-hook-form';

type RequestCopyModalProps = {
  readonly isOpen: boolean;
  readonly status: {
    readonly type: 'idle' | 'success' | 'error';
    readonly message?: string;
  };
  readonly isLoading: boolean;
  readonly onCancel: (e: React.SyntheticEvent) => void;
  readonly onSubmit: SubmitHandler<FieldValues>;
};

type ModalActionsProps = {
  readonly handleCancel: React.MouseEventHandler;
  readonly handleConfirm: React.MouseEventHandler;
  readonly isLoading: boolean;
};
function ModalActions({
  handleCancel,
  handleConfirm,
  isLoading,
}: ModalActionsProps): JSX.Element {
  return (
    <>
      <Button
        type="button"
        variant="primary"
        onClick={handleConfirm}
        pending={isLoading}
      >
        Send Request
      </Button>
      <Button
        type="button"
        variant="secondary"
        className="primary-btn-color"
        onClick={handleCancel}
      >
        Cancel
      </Button>
    </>
  );
}
export function RequestCopyModal({
  isOpen,
  status,
  isLoading,
  onCancel,
  onSubmit,
}: RequestCopyModalProps): JSX.Element {
  return (
    <Modal
      animate
      actions={() =>
        status.type !== 'success' &&
        status.type !== 'error' && (
          <ModalActions
            handleCancel={onCancel}
            handleConfirm={onSubmit}
            isLoading={isLoading}
          />
        )
      }
      dismissible
      onCancel={onCancel}
      open={isOpen}
      placement="center"
      className="confirm--dialog rounded-corners"
      size="sm"
      header={<h2>Request a copy</h2>}
    >
      {status.type === 'success' && (
        <AlertMessage
          type="success"
          heading={status.message}
          className="Alert-banner"
        />
      )}
      {status.type === 'error' && (
        <AlertMessage
          type="error"
          heading={status.message}
          inline
          className="Alert-banner"
        />
      )}
      {status.type !== 'success' && status.type !== 'error' && (
        <p>
          Confirm that you want to obtain a copy of the training record for this
          reservation.
        </p>
      )}
    </Modal>
  );
}
