import APIConfig from '../../configs/urlConfig';
import { OpenAPI } from '../index';
import Logger from '@/libs/logging/Logger';
import { acquireAccessToken } from '@/shared/helpers/getAccessToken';

function configureOpenAPI() {
  async function getToken() {
    if (import.meta.env.MODE === 'mock') {
      return 'dev-mode';
    }

    try {
      const token = await acquireAccessToken();
      return token;
    } catch (error: unknown) {
      let errorMessage = 'MSAL: AcquireTokenSilent failed. ';

      if (error instanceof Error) {
        errorMessage = errorMessage + error.message;
      }

      Logger.log({
        type: 'code',
        level: 'error',
        message: errorMessage,
      });

      return Promise.reject(errorMessage);
    }
  }

  OpenAPI.TOKEN = getToken;
  OpenAPI.HEADERS = {
    'Ocp-Apim-Subscription-Key': APIConfig.subscriptionKey,
  };
}

export default configureOpenAPI;
