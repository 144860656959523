import * as React from 'react';
import { Icon, Popover, TextInput } from '@cae/cobalt-react';

import './SearchDocumentsInput.css';
import { useDocumentsSearchQuery } from '@/features/documents/api/useDocumentsSearchQuery';
import SearchRow from '@/features/documents/components/search-documents-input/SearchRow';

export function SearchDocumentsInput({
  handleFolderClick,
  isSearchHintVisible = true,
  keyword = '',
  setKeyword,
  setShowResults,
  type,
  organizationId,
}: {
  handleFolderClick?: (arg: string) => void;
  isSearchHintVisible?: boolean;
  keyword: string;
  setKeyword: (arg: string) => void;
  setShowResults?: (arg: boolean) => void;
  type: 'cae' | 'company' | 'submitted';
  organizationId?: string;
}): JSX.Element {
  const [open, setOpen] = React.useState<boolean>(false);
  const popoverRef = React.useRef(null);
  const triggerRef = React.useRef(null);
  const { data, isInitialLoading } = useDocumentsSearchQuery({
    type,
    searchText: keyword.length > 2 ? keyword : '',
    organizationId,
  });

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    e.preventDefault();
    setKeyword(e.target.value ?? '');
  };

  const handleClear: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    setKeyword('');
    if (typeof setShowResults === 'function') setShowResults(false);
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      if (typeof setShowResults === 'function') setShowResults(true);
    }
  };

  return (
    <div style={{ flex: '0 1 50%' }}>
      <TextInput
        ref={triggerRef}
        onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
          e.preventDefault();
          setOpen(!open);
        }}
        className="search-input search-documents"
        field={{
          onChange: handleChange,
          placeholder: 'Search by document name',
          value: keyword,
          onKeyDown: handleKeyDown,
        }}
        prefixSlot={<Icon id="search-outline" size="md" />}
        suffixSlot={
          keyword ? (
            <button
              aria-label="Clear"
              className="btn--clear"
              onClick={handleClear}
              title="Clear"
              type="button"
            >
              <Icon id="close-circle-solid" size="sm" />
            </button>
          ) : (
            ''
          )
        }
      />
      {isSearchHintVisible && (
        <Popover
          open={open && keyword.length > 2}
          triggerRef={triggerRef}
          ref={popoverRef}
          placement="bottom-right"
          closeOnClickOutside
          closeOnEscape
          onClose={() => setOpen(false)}
          className="search-documents-preview"
          style={{
            minWidth: '50%',
            maxWidth: '90%',
            marginTop: '1rem',
            padding: '1rem',
          }}
        >
          {isInitialLoading ? (
            <p>Searching…</p>
          ) : data && Array.isArray(data.items) && data.items.length > 0 ? (
            <ul>
              {data.items.map(item => (
                <SearchRow
                  item={item}
                  onFolderClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    const { value } = e.target as HTMLButtonElement;
                    setKeyword('');
                    setOpen(false);
                    if (typeof handleFolderClick === 'function') {
                      handleFolderClick(value);
                    }
                  }}
                  searchTerm={keyword}
                  key={item.id}
                />
              ))}
            </ul>
          ) : (
            <p>No matches found</p>
          )}
        </Popover>
      )}
    </div>
  );
}

SearchDocumentsInput.displayName = 'SearchDocumentsInput';
