import APIConfig from '@/configs/urlConfig';
import { acquireAccessToken } from '../helpers/getAccessToken';

export async function downloadDocument(
  organizationId: string,
  documentId?: string
): Promise<boolean> {
  const tempToken = await acquireAccessToken();

  try {
    const response = await fetch(
      `${import.meta.env.VITE_WEBAPI_URI}/documents/${documentId}/blob`,
      {
        headers: new Headers({
          Authorization: `Bearer ${tempToken}`,
          'Ocp-Apim-Subscription-Key': APIConfig.subscriptionKey,
          'Organization-Id': organizationId,
        }),
      }
    );

    const data = await response.blob();

    const fileURL = URL.createObjectURL(data);
    const a = document.createElement('a');
    a.href = fileURL;
    a.target = '_blank';
    a.click();
    a.remove();
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}
