import { createContext, useState, useContext, useMemo } from 'react';

type TotalItems = { [key: string]: number };

interface TotalItemsContextType {
  totalItems: TotalItems;
  setTotalItemsState: (key: string, count: number) => void;
}

const TotalItemsContext = createContext<TotalItemsContextType | undefined>(
  undefined
);

function TotalItemsProvider({
  children,
}: Readonly<{
  children: JSX.Element;
}>): JSX.Element {
  const [totalItems, setTotalItems] = useState<TotalItems>({});

  const setTotalItemsState = (key: string, count: number): void => {
    setTotalItems(prev => ({ ...prev, [key]: count }));
  };

  const contextValue = useMemo(
    () => ({ totalItems, setTotalItemsState }),
    [totalItems]
  );

  return (
    <TotalItemsContext.Provider value={contextValue}>
      {children}
    </TotalItemsContext.Provider>
  );
}

function useTotalItems(): TotalItemsContextType {
  const context = useContext(TotalItemsContext);
  if (!context) {
    throw new Error('useTotalItems must be used within a TotalItemsProvider');
  }
  return context;
}

export { TotalItemsProvider, useTotalItems };
