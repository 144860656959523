import * as React from 'react';
import getInitials from '@/shared/helpers/getInitials';
import { ContextSwitch, OptionType } from '@cae/cobalt-react';
import { useGetCurrentOrganization } from '../../api/useGetCurrentOrganization';
import { useGetAllOrganizationsQuery } from '@/shared/api/useGetOrganization';
import { useUserInfo } from '@/contexts/userInfo';
import { useLocation } from 'react-router-dom';

function getOrgName(orgName: string): string {
  const selectedOrgName = orgName ? orgName.split(' ') : '--';
  return getInitials(selectedOrgName[0], selectedOrgName[1]);
}

function OrganizationSwitcher(): JSX.Element {
  const location = useLocation();
  const { data: organizationList = [] } = useGetAllOrganizationsQuery();
  const { handleOrgUpdate } = useGetCurrentOrganization();
  const { selectedOrganization, isInternalUser } = useUserInfo();

  const [defaultOption, setDefaultOption] = React.useState<
    OptionType | undefined
  >(undefined);

  const organizations: OptionType[] = React.useMemo(
    () =>
      organizationList
        .filter(org => org.name && org.active)
        .map(org => ({
          value: org.id ?? '',
          label: org.name ?? '',
          initials: getOrgName(org.name ?? ''),
        })),
    [organizationList]
  );
  const [filteredOrganizations, setFilteredOrganizations] =
    React.useState<OptionType[]>(organizations);
  React.useEffect(() => {
    const locationData = location.state?.userData;
    const orgId = locationData?.organizationId || selectedOrganization?.id;
    const orgName =
      locationData?.organizationName || selectedOrganization?.name;
    if (
      (!defaultOption && orgId && orgName) ||
      (defaultOption &&
        (defaultOption.value !== orgId || defaultOption.label !== orgName))
    ) {
      setDefaultOption({
        value: orgId,
        label: orgName,
        initials: getOrgName(orgName),
      });
    }
  }, [location.state, selectedOrganization, defaultOption]);

  function handleOrgFilter(value: string): void {
    const filteredOrgs: OptionType[] = organizations.filter(
      org =>
        org.label &&
        org.value &&
        org.label.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOrganizations(filteredOrgs);
  }

  const options: OptionType[] =
    filteredOrganizations.length > 0 ? filteredOrganizations : organizations;
  return defaultOption || isInternalUser ? (
    <ContextSwitch
      key={defaultOption?.value}
      defaultOption={defaultOption}
      options={options}
      onOptionClick={e => handleOrgUpdate(e.currentTarget.value)}
      onFilterChange={e => handleOrgFilter(e.currentTarget.value)}
      withFilter
    />
  ) : (
    <></>
  );
}

export default OrganizationSwitcher;
