import { Tabs } from '@cae/cobalt-react';

import { UserFilters } from '@/features/users/components/filter-modal/useFilterModalReducer';
import UsersListTable from '../users-list-table/UsersListTable';
import { useTotalItems } from '@/contexts/TotalItemsContext';

type UserTab = {
  label: string;
  countType: 'activeUsers' | 'inActiveUsers';
  dataType: 'active' | 'inactive';
};

const USERS_TABS: UserTab[] = [
  { label: 'Active', countType: 'activeUsers', dataType: 'active' },
  { label: 'Inactive', countType: 'inActiveUsers', dataType: 'inactive' },
];

type UsersListTabProps = {
  filters: UserFilters;
  searchText: string;
};

export function UsersListTabs({
  filters,
  searchText,
}: UsersListTabProps): JSX.Element {
  const { totalItems } = useTotalItems();

  return (
    <div>
      <Tabs variant="contained">
        {({ selectedIndex }) => {
          return (
            <>
              <Tabs.TabList>
                {USERS_TABS.map((tab, i) => (
                  <Tabs.Tab
                    id={`tab-users--${tab.countType}`}
                    key={`${tab.countType}`}
                    selected={i === selectedIndex}
                  >
                    {tab.label}{' '}
                    <i className="count">({totalItems[tab.dataType] ?? 0})</i>
                  </Tabs.Tab>
                ))}
              </Tabs.TabList>

              {USERS_TABS.map((panel, i) => (
                <Tabs.TabPanel
                  key={panel.label}
                  id={`tab-panel--${panel.dataType}`}
                  selected={i === selectedIndex}
                  aria-labelledby="tab--active--users"
                >
                  <UsersListTable
                    userType={`${panel.dataType}`}
                    filters={filters}
                    searchText={searchText}
                  />
                </Tabs.TabPanel>
              ))}
            </>
          );
        }}
      </Tabs>
    </div>
  );
}
