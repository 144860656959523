import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from 'react-router-dom';
import { UserInfoProvider } from '@/contexts/userInfo';
import router from './router';
import { AcceptCookiesProvider } from '@/configs/acceptCookiesContext';
import './App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { TotalItemsProvider } from './contexts/TotalItemsContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App(): JSX.Element {
  return (
    <QueryClientProvider client={queryClient}>
      <AcceptCookiesProvider>
        <TotalItemsProvider>
          <UserInfoProvider>
            <RouterProvider router={router} />
            <ReactQueryDevtools position="bottom-right" />
          </UserInfoProvider>
        </TotalItemsProvider>
      </AcceptCookiesProvider>
    </QueryClientProvider>
  );
}

export default App;
