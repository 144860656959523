import * as React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Dropdown,
  Icon,
  OptionType,
  ScreenContent,
  ScreenHeader,
} from '@cae/cobalt-react';

import { useHasPermission } from '@/shared/hooks/useHasPermission';
import { DataLoadingError, DataLoadingGrid, NoData } from '@/shared/components';

import { useDocumentsQuery } from '@/features/documents/api/useDocumentsQuery';
import {
  Breadcrumbs,
  DocumentListing,
  DocumentSearchListing,
  SearchDocumentsInput,
} from '@/features/documents/components';
import { useGetAllOrganizationsQuery } from '@/shared/api/useGetOrganization';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { EmptyCustomer } from '@/shared/components/empty-states/EmptyCustomer';

export function CustomerDocumentsOldPage(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: organizationList = [] } = useGetAllOrganizationsQuery();
  const organizations: OptionType[] = React.useMemo(
    () =>
      organizationList
        .filter(org => org.name && org.active)
        .map(org => ({
          value: org.id ?? '',
          label: org.name ?? '',
        })),
    [organizationList]
  );
  const { customerDocumentsSubmit } = useFlags();
  const [customerId, setCustomerId] = React.useState('');

  const [keyword, setKeyword] = React.useState<string>('');
  const [showResults, setShowResults] = React.useState<boolean>(false);
  const [currentFolder, setCurrentFolder] = React.useState<string | undefined>(
    location.state?.parentFolderId || undefined
  );

  React.useEffect(() => {
    if (location.state?.parentFolderId) {
      setCurrentFolder(location.state?.parentFolderId);
    }
    setKeyword('');
  }, [location.state?.parentFolderId]);

  const { data, isError, isLoading, isSuccess } = useDocumentsQuery({
    type: 'company',
    currentFolder,
    organizationId: customerId || undefined,
  });

  const { parentFolders = [], items } = data || {};

  const handleAddDocumentsClick: React.MouseEventHandler<
    HTMLButtonElement
  > = e => {
    e.preventDefault();
    navigate('/documents/company/submit');
  };

  const handleFolderClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    const { value } = e.target as HTMLButtonElement;
    setKeyword('');
    setShowResults(false);
    setCurrentFolder(value || undefined);
  };

  const handleSearchFolderClick = (id: string | undefined): void => {
    setCurrentFolder(id);
  };

  const onClear = (): void => {
    setCustomerId('');
  };

  React.useEffect(() => {
    setCurrentFolder(undefined);
  }, [customerId]);
  const hasPermission = useHasPermission();
  return (
    <>
      <div className="page-wrapper customer-documents">
        <ScreenHeader elevation="base">
          <hgroup>
            <h2>Customer training documents</h2>
          </hgroup>
          <div className="main-cta">
            {hasPermission('document_upload') && customerDocumentsSubmit ? (
              <Button
                data-testid="submitDocumentButton"
                type="button"
                variant="primary"
                size="md"
                onClick={handleAddDocumentsClick}
              >
                Submit document
              </Button>
            ) : null}
            <div className="customer-select">
              <Dropdown
                emptyText="Select a customer"
                field={{
                  onChange: e => setCustomerId(e.target.value),
                  value: customerId,
                }}
                filter
                options={organizations}
                suffixSlot={
                  <button
                    aria-label="Clear"
                    className="btn--clear"
                    onClick={onClear}
                    title="Clear"
                    type="button"
                  >
                    <Icon size="sm" id="close-circle-solid" />
                  </button>
                }
              />
            </div>
          </div>
          {customerId && (
            <div className="toolbar">
              <SearchDocumentsInput
                keyword={keyword}
                setKeyword={setKeyword}
                setShowResults={setShowResults}
                handleFolderClick={handleSearchFolderClick}
                type="company"
                organizationId={customerId}
              />
            </div>
          )}
        </ScreenHeader>
        {!showResults ? (
          <ScreenContent>
            {isLoading && <DataLoadingGrid numberRows={15} numberColumns={1} />}
            {isError && <DataLoadingError />}
            {isSuccess &&
              customerId &&
              items &&
              items?.length > 0 &&
              parentFolders?.length > 0 && (
                <Breadcrumbs
                  parentFolders={parentFolders}
                  onFolderClick={handleFolderClick}
                />
              )}
            {!customerId && <EmptyCustomer />}
            {isSuccess && customerId && items && items?.length < 1 && (
              <NoData />
            )}
            {isSuccess && customerId && items && items?.length > 0 && (
              <DocumentListing data={items} onFolderClick={handleFolderClick} />
            )}
          </ScreenContent>
        ) : (
          <ScreenContent>
            <DocumentSearchListing
              type="company"
              keyword={keyword}
              handleFolderClick={handleFolderClick}
            />
          </ScreenContent>
        )}
      </div>
      <Outlet />
    </>
  );
}

CustomerDocumentsOldPage.displayName = 'CustomerDocumentsOldPage';
