import { loginRequest } from '@/configs/authConfig';
import { msalInstance } from '@/shared/hooks/msalInstance';

export const acquireAccessToken = async (): Promise<string> => {
  const { msal } = await msalInstance();

  if (!msal.getActiveAccount()) {
    throw new Error('No active account found');
  }

  const account = msal.getActiveAccount();
  if (!account) {
    throw new Error('No active account found');
  }

  const request = {
    account,
    ...loginRequest,
  };

  try {
    const { idToken } = await msal.acquireTokenSilent(request);
    return idToken;
  } catch (error) {
    console.error(
      'Silent token acquisition failed, falling back to interactive',
      error
    );

    const response = await msal.acquireTokenPopup(request);
    return response.accessToken;
  }
};
