import { Outlet } from 'react-router-dom';
import {
  Button,
  Icon,
  Modal,
  ScreenHeader,
  ScreenContent,
} from '@cae/cobalt-react';

import { useViewportSize } from '@/shared/hooks/useViewportSize';
import useReservationFilter from '@/features/reservations/hooks/useReservationFilter';

import {
  ReservationsTabs,
  ReservationsAlertCards,
  RequestTrainingButton,
  SearchReservationsInput,
  FilterModal,
} from '@/features/reservations';

import './ReservationsPage.css';
import { useReservationsMetadataQuery } from '@/features/reservations/api/useReservationsMetadataQuery';
import HasAccess from '@/shared/components/has-access/HasAccess';
import ReservationsExport from '@/features/reservations/components/reservations-export/ReservationsExport';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';

export function ReservationsPage(): JSX.Element {
  const { width } = useViewportSize();
  const { reservationsexport } = useFlags();
  // Filters
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
  const toggleFiltersModal = (): void => setFiltersOpen(!filtersOpen);
  const { filters, resetFilters, toggleAllFilters, filterCounter } =
    useReservationFilter();
  const filterCount = filterCounter();
  const { data: metadata = {}, isLoading } =
    useReservationsMetadataQuery(filters);
  const { filterOptions = {} } = metadata;
  // Search
  const [needle, setNeedle] = useState<string>('');
  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = e => {
    const { value } = e.target;
    setNeedle(value);
  };

  return (
    <HasAccess permissions="view_reservations">
      <section className="page page-reservations">
        <div className="page-wrapper">
          <ScreenHeader className="reservations-header" elevation="base">
            <hgroup>
              <h2>Training reservations</h2>
              <RequestTrainingButton
                className="main-cta"
                label="Request new training"
              />
            </hgroup>
            <ReservationsAlertCards />
            <div className="toolbar">
              <SearchReservationsInput
                onChange={handleSearch}
                onClear={e => {
                  e.preventDefault();
                  setNeedle('');
                }}
                value={needle}
              />
              <Button
                className="btn-filters"
                type="button"
                variant="ghost"
                onClick={toggleFiltersModal}
                disabled={isLoading}
              >
                <Icon
                  id={`filter-${filterCount ? 'solid' : 'outline'}`}
                  size="sm"
                />
                <span className="label">Filters</span>
                {`${filterCount ? `(${filterCount})` : ''}`}
              </Button>
              {reservationsexport && (
                <ReservationsExport filters={filters} searchText={needle} />
              )}
            </div>
          </ScreenHeader>
          <ScreenContent>
            <ReservationsTabs filters={filters} searchText={needle} />
          </ScreenContent>
        </div>

        <Outlet />

        <Modal
          open={filtersOpen}
          onCancel={toggleFiltersModal}
          placement={width < 480 ? 'bottom' : 'right'}
          dismissible
          size="md"
          className="filter--dialog"
        >
          {filtersOpen && (
            <FilterModal
              filterOptions={filterOptions}
              filters={filters}
              resetFilters={resetFilters}
              toggleAllFilters={toggleAllFilters}
              toggleFilterSection={toggleFiltersModal}
            />
          )}
        </Modal>
      </section>
    </HasAccess>
  );
}
