import * as React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Dropdown,
  Icon,
  OptionType,
  ScreenContent,
  ScreenHeader,
} from '@cae/cobalt-react';

import { DataLoadingError, DataLoadingGrid, NoData } from '@/shared/components';
import { useHasPermission } from '@/shared/hooks/useHasPermission';

import { DocumentsUpdatedListing } from '@/features/documents/components/documents-updated-listing/DocumentsUpdatedListing';
import { useDocumentTreeQuery } from '@/features/documents/api/useDocumentsQuery';
import { BreadcrumbsUpdated } from '@/features/documents/components/breadcrumbs/BreadcrumbsUpdated';
import { DocumentElement } from '@/open-api';
import { SearchDocumentsUpdatedInput } from '@/features/documents/components/search-documents-input/SearchDocumentsUpdatedInput';
import { useFlags } from 'launchdarkly-react-client-sdk';
import './css/CustomerDocuments.css';
import { useGetAllOrganizationsQuery } from '@/shared/api/useGetOrganization';
import { EmptyCustomer } from '@/shared/components/empty-states/EmptyCustomer';

export function CustomerDocumentsPage(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: organizationList = [] } = useGetAllOrganizationsQuery();
  const organizations: OptionType[] = React.useMemo(
    () =>
      organizationList
        .filter(org => org.name && org.active)
        .map(org => ({
          value: org.id ?? '',
          label: org.name ?? '',
        })),
    [organizationList]
  );
  const { customerDocumentsSubmit } = useFlags();
  const [customerId, setCustomerId] = React.useState('');

  const hasPermission = useHasPermission();
  const [keyword, setKeyword] = React.useState<string>('');
  const [showResults, setShowResults] = React.useState<boolean>(false);
  const [currentFolder, setCurrentFolder] = React.useState<string | undefined>(
    location.state?.parentFolderId || undefined
  );
  const [filteredItems, setFilteredItems] = React.useState<
    Array<DocumentElement>
  >([]);
  const [parentFolders, setParentFolders] = React.useState<
    Array<DocumentElement>
  >([]);
  const {
    data: jsonData,
    isLoading,
    isError,
    isSuccess,
  } = useDocumentTreeQuery({
    type: 'company',
    searchText: keyword || undefined,
    organizationId: customerId || undefined,
  });
  const filterData = React.useCallback((): void => {
    if (keyword && jsonData) {
      const searchResults = jsonData.filter(item =>
        item.name?.toLowerCase().includes(keyword.toLowerCase())
      );
      setFilteredItems(searchResults);
      setShowResults(true);
    } else if (jsonData) {
      const folderItems = jsonData.filter(item =>
        currentFolder ? item.parentId === currentFolder : item.parentId === null
      );
      setFilteredItems(folderItems);
      setShowResults(false);
      const parents = [];
      let parentId = currentFolder;
      while (parentId) {
        const parent = jsonData.find(item => item.id === parentId);
        if (parent) {
          parents.unshift(parent);
          parentId = parent.parentId as string;
        } else {
          parentId = undefined;
        }
      }
      setParentFolders(parents);
    }
  }, [keyword, currentFolder, jsonData, setFilteredItems, setParentFolders]);

  const filterDataMemoized = React.useCallback(() => {
    filterData();
  }, [filterData]);

  React.useEffect(() => {
    if (isSuccess) {
      filterDataMemoized();
    }
  }, [currentFolder, keyword, isSuccess, filterDataMemoized]);

  const handleAddDocumentsClick: React.MouseEventHandler<
    HTMLButtonElement
  > = e => {
    e.preventDefault();
    navigate('/documents/cae/submit');
  };

  const handleFolderClick = (folderId: string): void => {
    if (folderId === 'root') {
      setCurrentFolder(undefined);
    } else {
      setCurrentFolder(folderId);
    }
    setKeyword('');
    setShowResults(false);
  };

  const handleSearchFolderClick = (id: string | undefined): void => {
    setCurrentFolder(id);
  };

  const onClear = (): void => {
    setCustomerId('');
  };

  React.useEffect(() => {
    setCurrentFolder(undefined);
  }, [customerId]);

  return (
    <>
      <div className="page-wrapper customer-documents">
        <ScreenHeader elevation="base">
          <hgroup>
            <h2>Customer training documents</h2>
          </hgroup>
          {hasPermission('document_upload') && customerDocumentsSubmit ? (
            <div className="main-cta">
              <Button
                type="button"
                variant="primary"
                size="md"
                onClick={handleAddDocumentsClick}
                data-testid="submitDocumentButton"
              >
                Submit document
              </Button>
            </div>
          ) : null}
          <div className="customer-select">
            <Dropdown
              emptyText="Select a customer"
              field={{
                onChange: e => setCustomerId(e.target.value),
                value: customerId,
              }}
              filter
              options={organizations}
              suffixSlot={
                <button
                  aria-label="Clear"
                  className="btn--clear"
                  onClick={onClear}
                  title="Clear"
                  type="button"
                >
                  <Icon size="sm" id="close-circle-solid" />
                </button>
              }
            />
          </div>

          {customerId && (
            <div className="toolbar">
              <SearchDocumentsUpdatedInput
                jsonData={jsonData || []}
                keyword={keyword}
                setKeyword={setKeyword}
                setShowResults={setShowResults}
                handleFolderClick={handleSearchFolderClick}
              />
            </div>
          )}
        </ScreenHeader>
        {!showResults ? (
          <ScreenContent>
            {isLoading && <DataLoadingGrid numberRows={15} numberColumns={1} />}
            {isError && <DataLoadingError />}
            {isSuccess &&
              parentFolders?.length > 0 &&
              filteredItems?.length > 0 && (
                <BreadcrumbsUpdated
                  parentFolders={parentFolders}
                  onFolderClick={handleFolderClick}
                />
              )}
            {!customerId && <EmptyCustomer />}
            {isSuccess && customerId && filteredItems?.length === 0 && (
              <NoData />
            )}
            {isSuccess && customerId && filteredItems?.length > 0 && (
              <DocumentsUpdatedListing
                data={filteredItems}
                onFolderClick={handleFolderClick}
              />
            )}
          </ScreenContent>
        ) : (
          <ScreenContent>
            {isSuccess && customerId && filteredItems?.length === 0 ? (
              <NoData />
            ) : (
              <DocumentsUpdatedListing
                data={filteredItems}
                onFolderClick={handleFolderClick}
              />
            )}
          </ScreenContent>
        )}
      </div>
      <Outlet />
    </>
  );
}

CustomerDocumentsPage.displayName = 'CustomerDocumentsPage';
