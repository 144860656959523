import { Button, Icon } from '@cae/cobalt-react';
import Spinner from '@/shared/components/file-upload/spinner/Spinner';
import { useDownloadFile } from '@/shared/hooks/useDownloadFile';
import type { DocumentDto, DocumentElement } from '@/open-api';
import { toastMessages } from '@/shared/helpers/toastMessages';

export function DownloadDocumentAction({
  data,
  permitted = false,
}: Readonly<{
  data: Omit<DocumentDto, 'isFolder'> | DocumentElement;
  notificationContainerId?: string;
  permitted?: boolean;
}>): JSX.Element {
  const { downloadFile, status, error } = useDownloadFile({
    onSuccess: () => {
      toastMessages.success(`Document downloaded successfully`);
    },
    onError: () => {
      toastMessages.error(`Document download failed: ${error}`);
    },
  });
  const handleClick = (): void => {
    downloadFile({
      url: `/documents/${data.id}/blob`,
      fileName: `${(data as DocumentElement)?.name}`,
      fileId: data.id,
    });
  };

  if (!permitted) return <></>;

  let icon;
  if (status === 'pending') {
    icon = <Spinner />;
  } else if (status === 'rejected') {
    icon = <Icon id="error-outline" size="md" color="red" />;
  } else {
    icon = <Icon id="download-outline" size="md" />;
  }

  return (
    <Button
      type="button"
      variant="icon"
      size="md"
      onClick={handleClick}
      disabled={status === 'pending'}
      title="Download document"
    >
      {icon}
    </Button>
  );
}

DownloadDocumentAction.displayName = 'DownloadDocumentAction';
