import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { UserManagementService, UserResponse } from '@/open-api';

export function useGetUserDiscrepancies(
  userId: string
): UseQueryResult<UserResponse, Error> {
  return useQuery<UserResponse, Error>({
    queryKey: ['usersSync', userId],
    queryFn: () => UserManagementService.getUserDiscrepancies({ id: userId }),
    enabled: !!userId?.trim(),
  });
}
