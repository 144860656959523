import { Modal, Button, Card, Avatar, Icon } from '@cae/cobalt-react';
import { usePendingLetterQuery } from '@/features/instructor-management/api/usePendingLetterQuery.ts';
import { DataLoadingError, DataLoadingGrid } from '@/shared/components';
import { NoData } from '@/features/record-details/components/empty-states/NoData.tsx';
import { getNameInitials } from '@/features/instructor-management/shared/Utils.tsx';
import './PendingLettersDrawer.css';
import ApprovalLetterUploadModal from '@/features/instructor-management/components/approval-letter-upload-modal/ApprovalLetterUploadModal.tsx';
import { useState } from 'react';
import { PendingApprovalLetter } from '@/open-api';

interface PendingLettersDrawerProps {
  pendingLettersDrawerOpen: boolean;
  togglePendingLettersDrawer: () => void;
}

interface SuccessInstructors {
  [key: string]: boolean;
}

const PendingLettersDrawer = ({
  pendingLettersDrawerOpen,
  togglePendingLettersDrawer,
}: Readonly<PendingLettersDrawerProps>): JSX.Element => {
  const { data, isError, isLoading, isSuccess } = usePendingLetterQuery(
    pendingLettersDrawerOpen
  );
  const { pendingApprovalLetters = [] } = data ?? {};
  const [selectedInstructor, setSelectedInstructor] =
    useState<PendingApprovalLetter>();
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [successInstructors, setSuccessInstructors] =
    useState<SuccessInstructors>({});
  const [formKey, setFormKey] = useState<string>(`${Date.now()}`);
  const handleCloseDrawer = (): void => {
    setSuccessInstructors({});
    togglePendingLettersDrawer();
  };
  const toggleUploadModal = (requestId?: string): void => {
    if (requestId) {
      setSuccessInstructors({
        ...successInstructors,
        [requestId]: true,
      });
    }
    setFormKey(`${Date.now()}`);
    setIsUploadModalOpen(!isUploadModalOpen);
  };

  const handleUploadClick = (instructor: PendingApprovalLetter): void => {
    setIsUploadModalOpen(true);
    setSelectedInstructor(instructor);
  };

  const getBodyContents = (): JSX.Element => {
    if (isLoading) {
      return (
        <div className="info-section">
          <DataLoadingGrid numberColumns={1} numberRows={8} />
        </div>
      );
    }
    if (isError) {
      return (
        <DataLoadingError
          heading={
            'An error occurred while fetching the check airmen list. Please try again later.'
          }
        />
      );
    }
    if (isSuccess && !pendingApprovalLetters.length) {
      return <NoData heading={'No check airmen available.'} />;
    }

    return (
      <div className="list-section">
        {pendingApprovalLetters.map(instructor => {
          return (
            <Card key={instructor.instructorId} className="instructor-card">
              <div className="instructor-card__info">
                <div className="name-section">
                  <Avatar
                    initials={getNameInitials(instructor.instructorName ?? '')}
                    className="avatar"
                    size="sm"
                  />
                  <span className="instructor-name">
                    {instructor.instructorName}
                  </span>
                </div>
                <div className="details">
                  <div className="details__item">
                    <span>Program </span>
                    <span className="item__text">{instructor.program}</span>
                  </div>
                  <div className="details__item">
                    <span>Location </span>
                    <span className="item__text">
                      {instructor.trainingCenterName}
                    </span>
                  </div>
                </div>
              </div>
              {instructor.approvalLetterRequestId &&
              successInstructors[instructor.approvalLetterRequestId] ? (
                <div className="success-text">
                  <Icon id={'checkmark-circle-solid'} />
                  <span>Approval letter uploaded.</span>
                </div>
              ) : (
                <Button
                  variant="ghost"
                  onClick={() => handleUploadClick(instructor)}
                  className="action-button"
                >
                  <Icon id={'upload-outline'} /> Upload
                </Button>
              )}
            </Card>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <Modal
        open={pendingLettersDrawerOpen}
        onCancel={handleCloseDrawer}
        placement="right"
        dismissible
        size="md"
      >
        <div className="instructor-pending-letters-drawer">
          <header>
            <h3>Upload approval letters</h3>
            <p className="sub-header">
              The following check airmen require their approval letter, please
              upload them below.
            </p>
          </header>
          {getBodyContents()}
        </div>
      </Modal>
      <ApprovalLetterUploadModal
        isUploadModalOpen={isUploadModalOpen}
        toggleUploadModal={toggleUploadModal}
        instructorName={selectedInstructor?.instructorName ?? ''}
        requestId={selectedInstructor?.approvalLetterRequestId ?? ''}
        formKey={formKey}
      />
    </>
  );
};

export default PendingLettersDrawer;
