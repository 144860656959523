import { format } from 'date-fns';

function getFormattedDate(
  dateString: string | undefined,
  dateFormat: string = 'yyyy-MMM-dd'
): string {
  if (!dateString) return '';
  const date = new Date(dateString);
  return format(
    new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()),
    dateFormat
  );
}

export default getFormattedDate;
