import { Modal, ProgressLine } from '@cae/cobalt-react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './SFRedirectModal.css';

function SFRedirectModal(): JSX.Element {
  const { state } = useLocation();
  const [progress, setProgress] = useState(70);

  const userData = state?.userData;
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(prevProgress =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);
  if (!userData) {
    return <></>;
  }
  return (
    <>
      {userData?.emailInUserService &&
      userData.id &&
      userData.isAlreadyExistsInOrg ? (
        <Modal
          id=""
          open={true}
          placement={'center'}
          dismissible={false}
          className="sfredirect-modal--container"
        >
          <h1>
            {userData.firstName} {userData.lastName} has already been onboarded
            to CAE Connect in the {userData.organizationName} organization.
            Taking you there...
          </h1>

          <br />
          <ProgressLine value={progress} />
        </Modal>
      ) : (
        <Modal
          id=""
          open={true}
          placement={'center'}
          dismissible={false}
          className="sfredirect-modal--container"
        >
          <h1>
            You selected to add {userData.firstName} {userData.lastName} as a
            user to the {userData.organizationName} organization in CAE Connect.
            Taking you there...
          </h1>
          <br />
          <ProgressLine value={progress} />
        </Modal>
      )}
    </>
  );
}

export default SFRedirectModal;
