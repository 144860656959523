import * as React from 'react';
import axios, { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { Modal } from '@cae/cobalt-react';

import { OrganizationAssignmentDto, UserAccessResponse } from '@/open-api';

import { useGetProfileQuery } from '../../shared/api/useGetProfileQuery';
import APIConfig from '../../configs/urlConfig';

import { RoleCard } from './RoleCard';
import { WelcomeVideo } from './WelcomeVideo';
import { PrimaryButton, SecondaryButton, SkipButton } from './ActionButtons';

import { ROLECARDS } from './mock';
import './FirstTimeUser.css';
import { acquireAccessToken } from '@/shared/helpers/getAccessToken';

const updateWelcomePage = async (
  orgId: string,
  userId: string
): Promise<AxiosResponse<unknown, unknown>> => {
  const tempToken = await acquireAccessToken();
  const config = {
    crossDomain: true,
    headers: {
      Authorization: `Bearer ${tempToken}`,
      'Ocp-Apim-Subscription-Key': APIConfig.subscriptionKey,
    },
  };
  return axios.put(
    `${APIConfig.apiUrl}/users/${userId}/welcome`,
    {
      selectedOrganization: orgId,
    },
    config
  );
};

function getCurrentOrganization(
  res: UserAccessResponse | undefined
): OrganizationAssignmentDto | undefined {
  return res?.organizations?.find(org => org.id === res.selectedOrganization);
}

function showWelcomePage(
  org?: OrganizationAssignmentDto,
  userId?: string
): false | Promise<boolean> {
  if (!userId || !org || !org.id || !org.welcomePage) return false;

  return updateWelcomePage(org.id, userId).then(res => {
    return res.status === 200;
  });
}

function FirstTimeUser(): JSX.Element {
  const [showVideo, setShowVideo] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);

  const { data } = useGetProfileQuery();
  const organization = getCurrentOrganization(data);
  const roleCount = organization?.roles?.length ?? 0;

  const { t } = useTranslation();

  React.useEffect(() => {
    if (showWelcomePage(organization, data?.id)) {
      setOpenModal(true);
    }
  }, [data, organization]);

  React.useLayoutEffect(() => {
    const videoEls = document.querySelectorAll('video');
    if (videoEls.length) {
      videoEls.forEach(v => {
        v.pause();
      });
    }
  }, [showVideo]);

  const handleStep = (): void => {
    setShowVideo(!showVideo);
  };

  const handleDone = (): void => {
    setOpenModal(false);
    setShowVideo(false);
  };

  const header = !showVideo ? (
    <>
      <h3>{t('firstTimeLogin.mainTitle')}</h3>
      <h4>{t('firstTimeLogin.subTitle')}</h4>
    </>
  ) : null;

  const children = !showVideo ? (
    ROLECARDS?.map(card =>
      organization?.roles?.map(role =>
        role.name === card.name ? (
          <RoleCard data={card} key={role.name} />
        ) : null
      )
    )
  ) : (
    <WelcomeVideo />
  );

  const actions = (): JSX.Element => (
    <>
      <SkipButton label={t('common.skip')} onClick={handleDone} />
      {!showVideo ? (
        <PrimaryButton label={t('common.next')} onClick={handleStep} />
      ) : (
        <>
          <SecondaryButton label={t('common.back')} onClick={handleStep} />
          <PrimaryButton label={t('common.done')} onClick={handleDone} />
        </>
      )}
    </>
  );

  return (
    <Modal
      animate
      size="md"
      className={`first-time-user ${showVideo ? 'with-video' : 'with-roles'}`}
      style={
        !showVideo && roleCount <= 2
          ? { height: 'fit-content' }
          : { height: 'auto' }
      }
      header={header}
      open={openModal}
      placement="center"
      onCancel={handleDone}
      actions={actions}
    >
      {children}
    </Modal>
  );
}

export default FirstTimeUser;
