import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetProfileQuery } from '@/shared/api/useGetProfileQuery';
import { useGetOrganizationsQuery } from '@/shared/api/useGetOrganization';
import LoaderWithLabel from '../loader/Loader';

function OrgSwitchWrapper(): JSX.Element {
  const navigate = useNavigate();
  const { data: profile } = useGetProfileQuery();
  const userId = profile?.id || '';
  const { data: updatedOrganizationList } = useGetOrganizationsQuery(userId);
  const location = useLocation();
  const { state: locationState } = location;
  useEffect(() => {
    setTimeout(() => {
      if (
        profile?.firstName &&
        updatedOrganizationList &&
        locationState.userData?.id &&
        locationState.userData.isAlreadyExistsInOrg
      ) {
        navigate(`/users/${locationState?.userData?.id}`, {
          state: { userData: locationState?.userData },
        });
      } else if (
        profile &&
        profile.firstName &&
        updatedOrganizationList &&
        locationState?.userData?.isUserIdValid &&
        locationState?.userData?.isOrganizationExists &&
        !locationState.userData.isAlreadyExistsInOrg
      ) {
        navigate(`/users`, { state: { userData: locationState?.userData } });
      } else if (locationState?.fromOrgSelector) {
        navigate('/home');
      } else {
        navigate('/home');
      }
    }, 2000);
  }, [navigate, profile, updatedOrganizationList, locationState]);

  return (
    <div>
      <LoaderWithLabel title="Switching Organizations" />
    </div>
  );
}

export default OrgSwitchWrapper;
