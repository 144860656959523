import {
  msalConfigB2C,
  loginRequest,
  msalConfigB2E,
} from '@/configs/authConfig';
import {
  AuthenticationResult,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';

export const msalInstance = async (): Promise<{
  msal: PublicClientApplication;
  idToken: string;
}> => {
  const isB2EUser = sessionStorage.getItem('auth-type') === 'b2e';
  const config = isB2EUser ? msalConfigB2E : msalConfigB2C;

  const msal = new PublicClientApplication(config);
  let idToken = '';

  await msal.initialize();

  if (!msal.getActiveAccount() && msal.getAllAccounts().length > 0) {
    msal.setActiveAccount(msal.getAllAccounts()[0]);
  }

  msal.enableAccountStorageEvents();

  msal.addEventCallback(async event => {
    if (
      event.eventType === EventType.LOGIN_SUCCESS &&
      (event?.payload as AuthenticationResult)?.account
    ) {
      const { account } = event.payload as AuthenticationResult;
      msal.setActiveAccount(account);
    }
  });

  if (msal.getActiveAccount()) {
    const response = await msal.acquireTokenSilent({
      ...loginRequest,
      account: msal.getActiveAccount()!,
    });

    idToken = response.idToken;
  }

  return { msal, idToken };
};
