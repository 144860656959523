import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { PaginationState } from '@tanstack/react-table';
import { RecordStatus, ReservationResponseDto } from '@/open-api';

import { useViewportSize } from '@/shared/hooks/useViewportSize';

import { useHasPermission } from '@/shared/hooks/useHasPermission';
import {
  useGetReservationsTableDataQuery,
  type ReservationType,
  type ReservationSortingOptions,
} from '../../api/useGetReservationsTableDataQuery';

import { ReservationsTableListing } from '../reservations-table-listing/ReservationsTableListing';
import { ReservationCardListing } from '../reservations-card-listing/ReservationsCardListing';
import { mapSorting } from '@/shared/helpers/mapSortingString.ts';
import { ColumnSort } from '@/shared/components';
import { useTotalItems } from '@/contexts/TotalItemsContext';

export const iconMapping: {
  [key: string]: string;
} = {
  neutral: 'remove-outline',
  neutralPending: 'circle-dotted-outline',
  none: 'none',
  warning: 'warning-circle-solid',
  danger: 'warning-triangle-solid',
  success: 'checkmark-circle-solid',
};

export const statusColors: {
  [key: string]: string;
} = {
  neutral: 'standard',
  success: 'green',
  warning: 'yellow',
  danger: 'red',
  pending: 'yellow',
  unknown: 'standard',
};

interface ReservationsListingProps {
  filters: {
    platforms: string[];
    locations: string[];
    scheduledStatus: Array<'Available' | 'NotAvailable'>;
    records: RecordStatus[];
    dateRange: (Date | undefined)[];
    courseTypes: Array<'Initial' | 'Recurrent' | 'Upgrade' | 'Other'>;
    etcaStatus: Array<'Sent' | 'NotSent' | 'NotApplicable' | 'Optional'>;
    tsaStatus: Array<
      'Approved' | 'WaitPeriodApproved' | 'Other' | 'NotApplicable'
    >;
    regulatoryAgencies: string[];
  };
  reservationsType: ReservationType;
  searchText?: string;
}

const getDefaultSortingByType = (type: ReservationType): ColumnSort[] => {
  if (type === 'upcoming') {
    return [{ id: 'startDate', desc: false }];
  }
  return [{ id: 'startDate', desc: true }];
};

export function ReservationsListing({
  filters,
  reservationsType = 'all',
  searchText = '',
}: ReservationsListingProps): JSX.Element {
  const navigate = useNavigate();
  const { width } = useViewportSize();
  const hasPermission = useHasPermission();
  const { totalItems, setTotalItemsState } = useTotalItems();
  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 100,
    });

  const [sorting, setSorting] = React.useState<ColumnSort[]>(
    getDefaultSortingByType(reservationsType)
  );

  React.useEffect(() => {
    setPagination(prevState => ({ ...prevState, pageIndex: 0 }));
  }, [filters, searchText]);

  const { data, status } = useGetReservationsTableDataQuery(
    reservationsType,
    pageIndex,
    pageSize,
    filters,
    searchText,
    mapSorting<ReservationSortingOptions>(sorting)
  );

  React.useEffect(() => {
    if (
      data?.totalItems != null &&
      totalItems[reservationsType] !== data.totalItems
    ) {
      setTotalItemsState(reservationsType, data.totalItems);
    }
  }, [data?.totalItems, reservationsType, totalItems, setTotalItemsState]);

  const reservations = data?.items ? data.items : [];

  const openDetails = (reservation: ReservationResponseDto): void => {
    if (reservation && reservation.reservationId) {
      if (hasPermission('view_reservations_details')) {
        navigate(`/reservations/${reservation.reservationId}`);
      }
    }
  };

  const commonProps = {
    data: reservations,
    openDetails,
    pageIndex,
    pageSize,
    reservationsType,
    searchText,
    setPagination,
    setSorting,
    defaultSortBy: sorting,
    queryStatus: status,
    totalPages: data?.totalPages ?? 1,
  };

  return width > 650 ? (
    <ReservationsTableListing {...commonProps} />
  ) : (
    <ReservationCardListing {...commonProps} />
  );
}

ReservationsListing.displayName = 'ReservationsListing';
