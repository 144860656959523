import { useGetProfileQuery } from '../api/useGetProfileQuery';

export const useCurrentRoles = (): string[] => {
  const { data } = useGetProfileQuery();

  const organizationRoles =
    data?.organizations
      ?.find(org => org.id === data.selectedOrganization)
      ?.roles?.map(role => role.name as string) ?? [];

  const userRoleMappingRoles =
    data?.userRoleMapping?.map(role => role.name as string) ?? [];

  return [...organizationRoles, ...userRoleMappingRoles];
};
